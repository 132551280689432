import "./AboutUs.css";

import React from "react";
import {
  IonContent,
  IonPage,
  IonImg,
  IonText,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonItemDivider,
} from "@ionic/react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const AboutUsPage: React.FC = () => {
  return (
    <IonPage>
      <Helmet>
        <title>
          Discover Relation Genius - Revolutionizing Human Connections
        </title>
        <meta
          name="description"
          content="Learn more about Relation Genius' mission and vision to revolutionize human connections through insightful, data-driven personality analysis, fostering meaningful relationships and personal growth."
        />
      </Helmet>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <Link to={"/"}>
            <div className="logoContainer">
              <IonImg src="assets/logo.svg" className="logo" />
              <IonTitle>Relation Genius</IonTitle>
            </div>
          </Link>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2em",
            justifyContent: "center",
          }}
        >
          <div className="image-container">
            <IonImg
              className={"circular-image"}
              src="https://firebasestorage.googleapis.com/v0/b/personality-analysis-bd059.appspot.com/o/public%2Fwebsite-assets%2Fmission.png?alt=media"
            />
          </div>
          <IonText
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "2em 0",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>Our Mission</h2>
            <p className={"limited-width"}>
              To revolutionize human connections by providing insightful,
              data-driven personality analysis that empowers individuals and
              organizations to foster meaningful, lasting relationships.
            </p>
          </IonText>
          <IonImg
            className={"circular-image"}
            src="https://firebasestorage.googleapis.com/v0/b/personality-analysis-bd059.appspot.com/o/public%2Fwebsite-assets%2Fvision.png?alt=media"
          />
          <IonText
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "2em 0",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>Our Vision</h2>
            <p className={"limited-width"}>
              To become the world's leading platform for self-awareness,
              personal growth, and team wellness, helping millions of users to
              embrace their unique traits and harmonize with the people around
              them.
            </p>
          </IonText>
          <IonItemDivider />
          <IonText className={"copyright"}>
            Crescent Software Labs - {new Date().getFullYear()}
          </IonText>
          <IonItemDivider />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AboutUsPage;
