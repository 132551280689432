import './Footer.css';
import React from "react";
import { IonButton, IonFooter, IonToolbar } from "@ionic/react";
import { useHistory } from "react-router";

export const Footer: React.FC = () => {
  const history = useHistory();

  const handleAboutUsClick = () => {
    history.push("/about-us");
  };

  const handleContactUsClick = () => {
    window.location.href = "mailto:m@crescentsoftwarelabs.com";
  };

  return <>
    <IonFooter>
      <IonToolbar className="sizeController">
        <IonButton
          color="secondary"
          fill="clear"
          onClick={handleAboutUsClick}
        >
          About Us
        </IonButton>
        <IonButton
          color="secondary"
          fill="clear"
          onClick={handleContactUsClick}
        >
          Contact Us
        </IonButton>
      </IonToolbar>
    </IonFooter>
  </>
}