import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBeLUBuWqUqgQMn6UCnJ_u2aToBrsAkuv4",
  authDomain: "personality-analysis-bd059.firebaseapp.com",
  projectId: "personality-analysis-bd059",
  storageBucket: "personality-analysis-bd059.appspot.com",
  messagingSenderId: "990183061813",
  appId: "1:990183061813:web:1edcd586cbc27ba003b47c",
  measurementId: "G-NLNXLZCN23",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);


// @ts-ignore
window.LOCAL =
  localStorage.getItem("DEBUG") || window.location.href.includes("localhost");
// @ts-ignore
if (window.LOCAL) {
  connectFunctionsEmulator(
    functions,
    localStorage.getItem("firebaseFunctionsHost") || "localhost",
    (localStorage.getItem("firebaseFunctionsPort") as unknown as number) || 5001
  );
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
