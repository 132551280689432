import React from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonImg,
  IonButton,
  IonFooter,
  IonItemDivider,
} from "@ionic/react";
import "./Home2.css";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";

const Home: React.FC = () => {
  const history = useHistory();

  const handleAboutUsClick = () => {
    history.push("/about-us");
  };

  const handleContactUsClick = () => {
    window.location.href = "mailto:m@crescentsoftwarelabs.com";
  };

  return (
    <IonPage>
      <Helmet>
        <title>
          Master the Art of Connection with RelationGenius | Personal &
          Professional Growth
        </title>
        <meta
          name="description"
          content="Unlock your potential for deep, meaningful relationships in every aspect of life. Embrace our data-driven personality analysis to revolutionize connections in your personal and professional worlds with RelationGenius."
        />
      </Helmet>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>RelationGenius</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <div className={"container"}>
          <div className={"overlay"}>
            <h1>
              Discover the Secrets to Unlocking Better Relationships with
              RelationGenius
            </h1>
          </div>
          <div className="img-wrapper">
            <IonImg src="https://firebasestorage.googleapis.com/v0/b/personality-analysis-bd059.appspot.com/o/public%2Fwebsite-assets%2Fhero.png?alt=media" />
          </div>
        </div>
        <div className="ion-padding sizeController">
          <div className={"sizeControllerChild"}>
            <h2>
              Unlock the power of personality, and communication insights with
              our simple three-step process
            </h2>
            <ol>
              <li>Take our in-depth personality tests</li>
              <li>
                Invite your partner, family, friends, or coworkers to join
              </li>
              <li>Receive personalized insights and recommendations</li>
            </ol>
            <IonItemDivider />
            <h2>
              Choose the plan that's perfect for you, and start building better
              relationships today
            </h2>
            <ul>
              <li>One-Time Analysis</li>
              <li>Monthly Subscription</li>
              <li>Corporate Package</li>
            </ul>
            <IonItemDivider />
            <h2>
              Join thousands of satisfied users who have transformed their
              relationships with RelationGenius
            </h2>
            <div>
              <div className="testimonial">
                "RelationGenius has helped me better understand myself and my
                loved ones. It has made a huge difference in my relationships."
                <span className="testimonial-author">- Fey B</span>
              </div>
              <div className="testimonial">
                "Thanks to RelationGenius, I now know how to communicate more
                effectively with my co-workers, leading to a more harmonious
                work environment."
                <span className="testimonial-author">- Mohamed T</span>
              </div>
            </div>
          </div>
        </div>
        <IonImg src="https://firebasestorage.googleapis.com/v0/b/personality-analysis-bd059.appspot.com/o/public%2Fwebsite-assets%2Fhappy-team.png?alt=media" />
        <div className={"ion-padding sizeController"}>
          <h2>
            Ready to unlock the secrets to better relationships? Sign up for
            RelationGenius today!
          </h2>
        </div>
        <IonFooter>
          <IonToolbar className="sizeController">
            <IonButton
              color="secondary"
              fill="clear"
              onClick={handleAboutUsClick}
            >
              About Us
            </IonButton>
            <IonButton
              color="secondary"
              fill="clear"
              onClick={handleContactUsClick}
            >
              Contact Us
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default Home;
