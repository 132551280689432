import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import React from "react";
import AboutUs from "./pages/AboutUs";
import Home2 from "./pages/Home2";
import AncientWisdom from "./pages/AncientWisdom";

setupIonicReact();

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          {/*<Menu />*/}
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Home2 />
            </Route>
            <Route path="/about-us" exact={true}>
              <AboutUs />
            </Route>
            <Route path="/ancient-wisdom" exact={true}>
              <AncientWisdom />
            </Route>
            <Route path="/thank-you" exact={true}>
              <AncientWisdom />
            </Route>

            {/*<Route path="/" exact={true}>*/}
            {/*  <Redirect to="/about-us" />*/}
            {/*</Route>*/}
            {/*<Route path="/page/:name" exact={true}>*/}
            {/*  <Page />*/}
            {/*</Route>*/}
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
