import { Helmet } from "react-helmet";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonInput,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";

import "./AncientWisdom.css";
import { Footer } from "../components/Footer";
import { findDOMNode } from "react-dom";
import { processorder } from "../../functions/src";

type FormValues = {
  email: string;
  fullName: string;
  birthday: string;
};
let stripeProductId =
  localStorage.getItem("DEBUG") || window.location.href.includes("localhost")
    ? "price_1MucBrDn8qsi76WjhEq3B3jt"
    : "price_1MxJhwLOVyXYmvv8MOYUeeKH";

let key =
  localStorage.getItem("DEBUG") || window.location.href.includes("localhost")
    ? "pk_test_nkKDniXCuwa2RxATCIgfL3pi00D2f8dKcH"
    : "pk_live_51MxJM6LOVyXYmvv8xEPzR4IA1MiRl0o8BJ6KwTsTj7L6FvZtSFpHBUZPn8kGAscDKU7ELl1q2QYiUc8hPPSKaeGw00QyxxMFBC";
const stripePromise = loadStripe(key);

const AncientWisdom: React.FC = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
    fullName: "",
    birthday: "",
    // email: "mohamedirvine@gmail.com",
    // fullName: "Mohamed",
    // birthday: "1993-10-10",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setSent] = useState(false);
  const [AB, setAB] = useState(Math.floor(Math.random() * Math.floor(2)));

  useEffect(() => {
    if (!isSent) {
      const analytics = getAnalytics();
      setSent(true);

      if (window.location.href.includes("payment_success")) {
        setSent(true);
        let data = JSON.parse(atob(window.location.href.split("=")[1]));
        logEvent(analytics, "begin_process", {
          ...(data || {}),
        });
        debugger;

        const processAncientWisdomSingleUser = httpsCallable(
          getFunctions(),
          "processAncientWisdomSingleUser"
        );
        let data3;
        (async () => {
          data3 = await processAncientWisdomSingleUser(data).then((data) => {
            debugger;
          });
        })();
        alert("Payment Succeeded, you will receive your email shortly!");
        // window.location.href = window.location.href.split("?payment_success=")[0];
      } else if (window.location.href.includes("payment_failure")) {
        alert("Payment Failed, please try again!");
        let data = JSON.parse(atob(window.location.href.split("=")[1]));
        logEvent(analytics, "payment_cancel_or_failure", {
          ...(data || {}),
        });
        debugger;
        setFormValues(data);
      }
    }
  }, []);

  function handleClick() {
    const element = document.getElementById("form");

    if (element) {
      // @ts-ignore
      findDOMNode(element)?.scrollIntoView?.({
        behavior: "smooth",
        block: "start",
      });
    } else {
      console.error('Element with the ID "personality-form" not found');
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    let x = formValues;
    let datam = btoa(
      JSON.stringify({ ...formValues, AB, product: "single_ancient" })
    );
    debugger;
    const stripe = await stripePromise;
    try {
      const processAncientWisdomSingleUser = httpsCallable(
        getFunctions(),
        "processorder"
      );
      let data3;
      await (async () => {
        data3 = await processAncientWisdomSingleUser({
          successUrl: `${window.location.href}?payment_success=${datam}`,
          cancelUrl: `${window.location.href}?payment_failure=${datam}`,
          ref: window.location.href,
        }).then((data) => {
          return data.data;
        });
        if (data3.url) {
          window.location.href = data3.url;
        } else {
          console.error("Failed to get the payment url");
        }
      })();

      // @ts-ignore
      // const data = await stripe?.redirectToCheckout({
      //
      //   lineItems: [{ price: stripeProductId, quantity: 1 }],
      //   mode: "payment",
      //   successUrl: `${window.location.href}?payment_success=${datam}`,
      //   cancelUrl: `${window.location.href}?payment_failure=${datam}`,
      //   customerEmail: formValues.email,
      //
      // });
      //
      // if (data?.error) {
      //   throw new Error(data?.error?.message);
      // }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      debugger;
    }
  };

  const handleInputChange = (name: string) => (event: CustomEvent) => {
    const { value } = event.detail;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <IonPage>
      <Helmet>
        <title>Relation Genius - Ancient Wisdom of Personality</title>
        <meta
          name="description"
          content="Discover what ancient wisdom reveals about your personality type based on your zodiac sign, numerology, and Chinese zodiac sign."
        />
      </Helmet>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <Link to={"/"}>
            <div className="logoContainer">
              <IonImg src="assets/logo.svg" className="logo" />
              <IonTitle>Relation Genius</IonTitle>
            </div>
          </Link>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {AB && (
          <section className="hero">
            <div className="hero-text">
              <p>
                <em>
                  "If you want to find the secrets of the universe, think in
                  terms of energy, frequency, and vibration." - Nikola Tesla
                </em>
              </p>
              <h1>Unlock the Secrets of Your Personality</h1>
              <h2>Experience the Power of Math and Ancient Wisdom</h2>
              <p>
                Discover your unique personality through the predictable
                patterns found in math, just like Zillow accurately predicts
                home values.
              </p>
              <h2>Your Birthdate: The Key to Your True Self</h2>
              <p>
                Your birthdate serves as the address, allowing us to pinpoint
                the hidden aspects of your personality in those patterns.
              </p>
              <h2>Unlock the Vibrations in Your Name</h2>
              <p>
                Each letter in your name carries a distinct vibration that
                resonates with and shapes your personality over time.
              </p>
            </div>

            <div className="hero-image">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/personality-analysis-bd059.appspot.com/o/public%2Fwebsite-assets%2Flook-left.png?alt=media"
                alt="Unlock Your Personality Secrets"
              />
            </div>
          </section>
        )}
        {!AB && (
          <section className="hero">
            <div className="hero-text">
              <p>
                <em>
                  "If you want to find the secrets of the universe, think in
                  terms of energy, frequency, and vibration." - Nikola Tesla
                </em>
              </p>
              <h1>Unlock the Secrets of Your Personality</h1>
              <h2>Embark on an Exciting Journey of Self-Discovery</h2>
              <p>
                Dive into the thrilling world of ancient wisdom and experience
                the magic of math as it unveils the hidden aspects of your
                unique personality.
              </p>
              <h2>Your Birthdate: The Gateway to Adventure</h2>
              <p>
                Unlock the door to your true self and uncover the amazing
                secrets that lie within, using your birthdate as the key.
              </p>
              <h2>Feel the Vibrations in Your Name</h2>
              <p>
                Experience the energy and power of each letter in your name as
                they resonate with your personality, creating an unforgettable
                journey of self-exploration.
              </p>
            </div>
            <div className="hero-image">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/personality-analysis-bd059.appspot.com/o/public%2Fwebsite-assets%2Flook-left.png?alt=media"
                alt="Unlock Your Personality Secrets"
              />
            </div>
          </section>
        )}
        <section className="form-section" id={"form"}>
          <h2>Get Your Personalized Personality Report Today!</h2>
          <p className="price">
            <strong>Only $19.99</strong>
          </p>
          <p>
            Enter your details below and receive a comprehensive report that
            reveals the hidden truths about your personality, strengths, and
            weaknesses.
          </p>
          <form id="personality-form">
            <>
              <IonInput
                type="text"
                name="fullName"
                labelPlacement={"floating"}
                label={"Full Name (as appears on birth certificate)"}
                value={formValues.fullName}
                onIonChange={handleInputChange("fullName")}
                autocomplete={"name"}
                fill={"outline"}
                required
              />

              <IonInput
                type="date"
                name="birthday"
                labelPlacement={"floating"}
                label={"birthday (real birthday)"}
                placeholder={""}
                autocomplete={"bday"}
                value={formValues.birthday}
                onIonChange={handleInputChange("birthday")}
                fill={"outline"}
                required
              />
              <IonInput
                type="email"
                name="email"
                label={"Email"}
                autocomplete={"email"}
                labelPlacement={"floating"}
                value={formValues.email}
                onIonChange={handleInputChange("email")}
                fill={"outline"}
                required
              />

              <IonButton expand="block" onClick={handleSubmit}>
                {isLoading ? (
                  <IonLoading message="Submitting form..." />
                ) : (
                  "Get My Report"
                )}
              </IonButton>
            </>
          </form>
          <p className="guarantee">
            100% Money Back Guarantee - If you're not satisfied with your
            report, we'll refund your purchase.
          </p>
        </section>

        <section className="benefits">
          <h2>Why Get a Personality Report?</h2>
          <div className="benefit">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/personality-analysis-bd059.appspot.com/o/public%2Fwebsite-assets%2Fjump.png?alt=media"
              alt="Achieve Your Goals"
            />
            <h3>Achieve Your Goals</h3>
            <p>
              Uncover your hidden strengths and leverage them to achieve
              personal and professional success.
            </p>
          </div>
          <div className="benefit">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/personality-analysis-bd059.appspot.com/o/public%2Fwebsite-assets%2Fhappy.png?alt=media"
              alt="Deepen Relationships"
            />
            <h3>Deepen Relationships</h3>
            <p>
              Gain insights into your interpersonal dynamics, improve your
              relationships and communicate more effectively.
            </p>
          </div>
          <div className="benefit">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/personality-analysis-bd059.appspot.com/o/public%2Fwebsite-assets%2Finner-peace.png?alt=media"
              alt="Find Inner Peace"
            />
            <h3>Find Inner Peace</h3>
            <p>
              Discover your true self, find inner balance, and unlock the path
              to personal growth and spiritual development.
            </p>
          </div>
          <div className="benefit">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/personality-analysis-bd059.appspot.com/o/public%2Fwebsite-assets%2Fsocializing.png?alt=media"
              alt="Placeholder image"
            />
            <h3>Have Fun & Connect</h3>
            <p>
              Enjoy learning about yourself and use your report as a
              conversation starter, making it fun and engaging to connect with
              others.
            </p>
          </div>
          <IonButton onClick={handleClick} expand="block">
            Get Your Report Today
          </IonButton>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default AncientWisdom;
